import React, { Component } from 'react';

class LineItem extends Component {
  constructor(props) {
    super(props);

    this.decrementQuantity = this.decrementQuantity.bind(this);
    this.incrementQuantity = this.incrementQuantity.bind(this);
  }

  decrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity - 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity);
  }

  incrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity + 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity);
  }

  render() {
    return (
      <li className="line-item">
        <div className="image">
          {this.props.line_item.variant.image ? <img src={this.props.line_item.variant.image.src} alt={`${this.props.line_item.title} product shot`} /> : null}
        </div>
        <div className="title">
          <span className="name">{this.props.line_item.title}</span>
          <span className="variant"> ({this.props.line_item.variant.title})</span>
        </div>
        <div className="meta">
          <div className="quantity">
            <button onClick={() => this.decrementQuantity(this.props.line_item.id)}>-</button>
            <span>{this.props.line_item.quantity}</span>
            <button onClick={() => this.incrementQuantity(this.props.line_item.id)}>+</button>
          </div>
          <span className="price">
            $ {(this.props.line_item.quantity * this.props.line_item.variant.price).toFixed(2)}
          </span>
          <button className="remove" onClick={() => this.props.removeLineItemInCart(this.props.line_item.id)}>×</button>
        </div>
      </li>
    );
  }
}

export default LineItem;
