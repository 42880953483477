import React, { Component } from 'react';
import { Link } from 'gatsby'
import { connect } from 'react-redux';
import { updateQuantityInCart, removeLineItemInCart } from '../modules/Product/products';
import { openCart, closeCart } from '../modules/Cart/cart'


import Layout from "../components/layout"
import LineItem from '../components/cart/LineItem'

class ShopifyCart extends Component {
  constructor(props) {
    super(props);
    this.openCheckout = this.openCheckout.bind(this);
  }

  componentDidMount() {
    if (this.props['*'] === 'cart-checkout') {
      this.props.closeCart()
    }
  }

  componentWillUnmount() {
    this.props.openCart()
  }

  openCheckout() {
    window.open(this.props.checkout.webUrl);
  }

  render() {
    let line_items;
    if (this.props.checkout.lineItems.length > 0) {
      line_items = this.props.checkout.lineItems.map((line_item) => {
        return (
          <LineItem
            updateQuantityInCart={this.props.updateQuantityInCart}
            removeLineItemInCart={this.props.removeLineItemInCart}
            key={line_item.id.toString()}
            line_item={line_item}
          />
        );
      });
    } else {
      line_items = (
        <div>
          <p>Your cart is empty.</p>
          <Link to="/shop">Go to the shop.</Link>
        </div>
      )
    }

    return (
      <Layout>
        <div className="cart">
          <h3 className="carousel-header">Cart</h3>
          <ul className="line-items">
            {line_items}
          </ul>
          <div className="totals">
            <div>
              Subtotal : <span>$ {this.props.checkout.subtotalPrice}</span>
            </div>
          </div>
          <div className="checkout">
            <button onClick={this.openCheckout}>Proceed to Payment</button>
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  checkout: state._product.checkout,
  isCartOpen: state._cart.isCartOpen
});

const connectedShopifyCart = connect(mapStateToProps, { updateQuantityInCart, removeLineItemInCart, openCart, closeCart })(ShopifyCart);

export default connectedShopifyCart



